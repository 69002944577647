<template>
    <base-page>
        <titlu-pagina Titlu="Raport proiecte" :AdaugaVisible='false'  />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='get_info()'>
                    <el-row :gutter="20">

                        <el-col :span='4'>
                            <el-form-item label='Proiecte' >
                                <el-select class='full-width' v-model='Filters.IdProiect' filterable >
                                    <el-option label='Toate' value='-1'></el-option>                                    
                                </el-select>
                            </el-form-item>
                        </el-col>                        
                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-card>
            <GraficGantt :tasks='Tasks' ref='gantt'/> 
        </el-card>
        
        
    </base-page>
</template>

<script src="https://cdn.jsdelivr.net/npm/gantt-schedule-timeline-calendar/dist/gstc.wasm.umd.min.js"></script>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from "@/pages/BasePage";
    import TitluPagina from '@/widgets/TitluPagina';
    import GraficGantt from '@/components/Grafic_gantt';
    import moment from 'moment';

    function getDate(hours) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const timeStamp = new Date(
            currentYear,
            currentMonth,
            currentDay,
            0,
            0,
            0
        ).getTime();
        return new Date(timeStamp + hours * 60 * 60 * 1000).getTime(); 
    }

    export default {
        name: "raport_proiecte",
        extends: BasePage,
        components: {
            'base-page': BasePage,
            'titlu-pagina': TitluPagina,
            GraficGantt
        },
        data () {
            return {
                Results: [ ],
                base_url: '',
                Filters: { IdProiect:'-1' },
                Tasks: [
                    {
                        duration: 2073600000,
                        id: 2,
                        label: "Managementul de Proiect",
                        parentId: 1,
                        percent: 0,
                        start: 1638828000000,
                        type: "task",
                        user: "-",
                    },
                    {
                        duration: 15811200000,
                        id: 1,
                        label: "A.N.A.N.P. - Pilon strategic în dezvoltarea comunităților locale și a mediului de afaceri prin consolidarea capacității administrative în ariile naturale protejate din România",
                        percent: 0,
                        start: 1625086800000,
                        type: "project",
                        user: "Tiberius Serban",
                        style: {
                            base: {
                                fill: "#8E44AD",
                                stroke: "#7E349D"
                            }
                        }
                    }
                ]
            }
        },
        methods: {
            async get_info(){
                var result      = await this.post("proiecte/get_raport_gantt", {id_proiect: this.Filters.IdProiect})                 
                // this.Tasks    = result.Results  
                // this.$refs.gantt.tasksUpdate(result.Results)   
                const duration = 19094400000;            
                let startDate = moment(1607292000000).format('YY-MM-DD');                
                // console.log(moment.unix(duration).format('DD'));
                console.log(duration / (24 * 60 * 60 * 1000)); 
                console.log(1607292000000, startDate);              
            },
            reset(){
                this.Filters = { IdProiect:'-1' };
                this.get_info();
            }
        },
        mounted(){
            this.base_url = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
